<template>
	<div>
		<v-menu :close-on-click="true" :close-on-content-click="true" bottom nudge-bottom="10">
			<template v-slot:activator="{on}">
				<div v-on="on" :class="`row-format centered ${iconOnly ? 'pipeline-icon' : 'pipeline-button'}`" v-tippy="{content: tippyLabel}">
					<v-icon color="black" :size="iconOnly ? 24 : 20" class="material-symbols-rounded">filter_alt</v-icon>
					<div :class="iconOnly ? 'opp-count-icon' : 'opp-count'" v-if="opportunities.length">{{opportunities.length}}</div>
				</div>
			</template>
			<div style="background-color: var(--v-white-base);" class="pa-4 font-14 text-left">
				<div v-for="opp in opportunities" :key="opp.id" class="row-format align-center">
					<client-avatar class="mr-1" :x-small="true" v-if="opp.clientId" :client="getClient(opp.clientId)"></client-avatar>
					<div style="white-space: nowrap" class="mr-2 pointer" @click="viewOpportunity(opp)">{{opp.name}}</div>
					<v-icon class="material-symbols-rounded ml-auto" color="secondary" size="20" @click="unlink(opp)">link_off</v-icon>
				</div>
				<div class="font-gray_70 brand-medium pointer mt-1" @click="linkOpportunity">+ Link to opportunity</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import OpportunityService from "@/modules/pipeline/OpportunityService";
	import ClientAvatar from "@/components/ClientAvatar";
	import OpportunitySelector from "@/modules/pipeline/OpportunitySelector";
	import OpportunityDetail from "@/modules/pipeline/opportunity/OpportunityDetail";

	export default {
		name: 'PipelineLinkWidget',

		props: ['itemType', 'itemId','clientId','iconOnly'],

		components: {ClientAvatar},

		data: function() {
			return {
				opportunities: [],
				opportunityService: new OpportunityService()
			};
		},

		mounted() {
			this.getLinked();
		},

		beforeDestroy() {},

		methods: {
			getLinked: function(){
				this.opportunityService.getLinkedWithActivity(this.itemType,this.itemId).then((res) => {
					this.opportunities.splice(0,this.opportunities.length);
					this.opportunities.push(... res.data);
				})
			},

			getClient: function(clientId){
				return this.$store.getters.getClientById(clientId);
			},

			unlink: function(opportunity){
				this.opportunityService.unlinkWithActivity(opportunity.id,this.itemType,this.itemId).then(() => this.getLinked());
			},

			viewOpportunity: function(opportunity){
				let binding = {
					id: opportunity.id,
					pipelineStages: null,
					pipelineFields: null,
				}
				this.$store.state.globalModalController.openModal(OpportunityDetail,binding);
			},

			linkOpportunity: function(){
				let binding = {
					clientId: this.clientId,
				}
				this.$store.state.globalModalController.openModal(OpportunitySelector,binding).then((res) => {
					if(res){
						this.opportunityService.linkToActivity(res.id,this.itemType,this.itemId).then(() => this.getLinked());
					}
				})
			}
		},

		computed: {
			tippyLabel: function(){
				if(this.opportunities.length){
					return `Linked to ${this.opportunities.length} ${this.opportunities.length > 1 ? 'opportunities' : 'opportunity'}`;
				}else{
					return 'Link to pipeline opportunity'
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.pipeline-button {
		border: 1px solid var(--v-gray_50-base);
		width: 32px;
		height: 32px;
		border-radius: 20px;
		position: relative;
		cursor: pointer;
		&:hover{
			background-color: var(--v-gray_10-base);
		}
	}

	.pipeline-icon {
		width: 24px;
		height: 24px;
		border-radius: 20px;
		position: relative;
		cursor: pointer;
		&:hover{
			background-color: var(--v-gray_10-base);
		}
	}


	.opp-count-icon {
		position: absolute;
		top: -4px;
		right:-4px;
		background-color: var(--v-accent-base);
		color: var(--v-secondary-base);
		font-size: 12px;
		font-weight: 500;
		width:16px;
		height:16px;
		border-radius: 12px;
		text-align: center;

	}

	.opp-count {
		position: absolute;
		top: -4px;
		right:-4px;
		background-color: var(--v-secondary-base);
		color: var(--v-white-base);
		font-size: 12px;
		font-weight: 500;
		width:16px;
		height:16px;
		border-radius: 12px;
		text-align: center;

	}
</style>
