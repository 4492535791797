var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-click": true,
            "close-on-content-click": true,
            bottom: "",
            "nudge-bottom": "10"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g(
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: { content: _vm.tippyLabel },
                            expression: "{content: tippyLabel}"
                          }
                        ],
                        class:
                          "row-format centered " +
                          (_vm.iconOnly ? "pipeline-icon" : "pipeline-button")
                      },
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: {
                            color: "black",
                            size: _vm.iconOnly ? 24 : 20
                          }
                        },
                        [_vm._v("filter_alt")]
                      ),
                      _vm.opportunities.length
                        ? _c(
                            "div",
                            {
                              class: _vm.iconOnly
                                ? "opp-count-icon"
                                : "opp-count"
                            },
                            [_vm._v(_vm._s(_vm.opportunities.length))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "div",
            {
              staticClass: "pa-4 font-14 text-left",
              staticStyle: { "background-color": "var(--v-white-base)" }
            },
            [
              _vm._l(_vm.opportunities, function(opp) {
                return _c(
                  "div",
                  { key: opp.id, staticClass: "row-format align-center" },
                  [
                    opp.clientId
                      ? _c("client-avatar", {
                          staticClass: "mr-1",
                          attrs: {
                            "x-small": true,
                            client: _vm.getClient(opp.clientId)
                          }
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "mr-2 pointer",
                        staticStyle: { "white-space": "nowrap" },
                        on: {
                          click: function($event) {
                            return _vm.viewOpportunity(opp)
                          }
                        }
                      },
                      [_vm._v(_vm._s(opp.name))]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "material-symbols-rounded ml-auto",
                        attrs: { color: "secondary", size: "20" },
                        on: {
                          click: function($event) {
                            return _vm.unlink(opp)
                          }
                        }
                      },
                      [_vm._v("link_off")]
                    )
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  staticClass: "font-gray_70 brand-medium pointer mt-1",
                  on: { click: _vm.linkOpportunity }
                },
                [_vm._v("+ Link to opportunity")]
              )
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }